const defineItemName = (window) => {
  const { _merchantId: merchantId } = window;

  return (itemName) => {
    return `${merchantId}-${itemName}`;
  };
};

const getStorageType = (storageType) => {
  const getItemName = defineItemName(window);

  return {
    get(itemName) {
      return storageType.getItem(getItemName(itemName));
    },

    set(itemName, value) {
      return storageType.setItem(getItemName(itemName), value);
    },

    remove(itemName) {
      return storageType.removeItem(getItemName(itemName));
    }
  };
};

const storageService = () => {
  const local = getStorageType(localStorage);
  const session = getStorageType(sessionStorage);

  return {
    local,
    session
  };
};

const storage = storageService();

export default storage;

