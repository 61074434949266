import events from '@/services/events';
import logger from '@/services/logger';



const errorHandlers = {
  generic(error, options = {}) {
    logger.error(error);

    const errorMessages = [];

    if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.forEach) {
      error.response.data.errors.forEach((e) => {
        if (e.message && !errorMessages.includes(e.message)) {
          errorMessages.push(e.message);
        }
      });
    }

    if (errorMessages.length === 0) {
      errorMessages.push(options.fallbackMessage || 'Something went wrong. Please try again later.');
    }

    errorMessages.forEach((message) => {
      events.$emit('notification', {
        clickToDismiss: true,
        message,
        type: 'error'
      });
    });
  },

  silent(error) {
    logger.error(error);
  }
};



export default errorHandlers;
