function joinPath(...args) {
  return args.map((part, i) => {
    if (!part) {
      return '';
    }

    if (i === 0) {
      return part.trim().replace(/[/]*$/g, '');
    }

    else {
      return part.trim().replace(/(^[/]*|[/]*$)/g, '');
    }
  }).filter((x) => {
    return x.length;
  }).join('/');
}



export default joinPath;
