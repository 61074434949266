<template>
  <p class="input-error" v-if="error">
    {{ error }}
  </p>
</template>



<script>
  export default {
    name: 'InputError',

    props: {
      error: {
        type: String,
        default: null
      }
    }
  };
</script>
