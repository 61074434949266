import store from '@/store';
import Vue from 'vue';



function configureGoogleAnalytics() {
  Vue.$ga.set('dimension1', store.state.merchant.name);
}



export default configureGoogleAnalytics;
