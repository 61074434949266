<template>
  <div class="container max-width-mobile">
    <div class="center" v-if="isLoading">
      <div class="loader" />
    </div>

    <div class="section center" v-else-if="surveyWasSubmitted">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--3 primary">
        Thank you for taking the survey. We really appreciate your time!
      </h1>

      <p>You can close this page now.</p>
    </div>

    <validated-form
      name="survey"
      :disabled="isProcessingSurvey"
      @valid-submit="submitSurvey"
      v-else>
      <div class="section center">
        <h1 class="title title--2 primary">
          {{ surveyForm.title || 'Customer Survey' }}
        </h1>

        <p class="message message--danger" v-if="failedToLoad">
          Oops! There was a problem loading the survey form. Please try again later.
        </p>

        <p v-else>
          {{ surveyForm.description || 'Thank you for being willing to give us feedback. Please answer the following questions:' }}
        </p>
      </div>

      <template v-if="!isLoading && !failedToLoad">
        <div class="section" v-for="question in surveyForm.questions" :key="question.id">
          <checklist-input
            v-if="question.questionType === 'Checklist' || question.questionType === 'MultipleSelect'"
            v-model="question.answer"
            :label="question.label"
            :name="'surveyQuestion' + question.id"
            :options="question.normalizedOptions"
            :validations="{
              required: question.required
            }" />

          <radio-input
            v-else-if="question.questionType === 'RadioList'"
            v-model="question.answer"
            :label="question.label"
            :name="'surveyQuestion' + question.id"
            :options="question.normalizedOptions"
            :validations="{
              required: question.required
            }" />

          <star-rating-input
            v-else-if="question.questionType === 'StarRating'"
            v-model="question.answer"
            :label="question.label"
            :name="'surveyQuestion' + question.id"
            :validations="{
              required: question.required
            }" />

          <text-input
            v-else-if="question.questionType === 'Textarea'"
            type="textarea"
            v-model="question.answer"
            :label="question.label"
            :name="'surveyQuestion' + question.id"
            :validations="{
              required: question.required
            }" />

          <text-input
            v-else
            type="text"
            v-model="question.answer"
            :label="question.label"
            :name="'surveyQuestion' + question.id"
            :validations="{
              required: question.required
            }" />
        </div>

        <div class="section section--alt-background">
          <button
            class="button button--primary button--large"
            :class="{
              'button--loading': isProcessingSurvey
            }"
            type="submit">
            Submit Survey
          </button>
        </div>
      </template>
    </validated-form>
  </div>
</template>



<script>
  import errorHandlers from '@/services/error-handlers';
  import http from '@/services/http';



  export default {
    name: 'SurveyPage',

    metaInfo() {
      return {
        title: 'Customer Survey'
      };
    },

    data() {
      return {
        failedToLoad: false,
        isLoading: false,
        isProcessingSurvey: false,
        surveyForm: {},
        surveyWasSubmitted: false
      };
    },

    created() {
      this.createdHandler();
    },

    methods: {
      createdHandler() {
        this.loadAllData();
      },

      async loadAllData() {
        try {
          this.isLoading = true;
          await this.loadSurvey();
        }

        catch (error) {
          errorHandlers.silent(error);
          this.failedToLoad = true;
        }

        finally {
          this.isLoading = false;
        }
      },

      async loadSurvey() {
        try {
          const formsResponse = await http.get('/cardfree-api/v1/form', {
            params: {
              platform: this.$route.query.source,
              surveyType: this.$route.params.surveyType
            }
          });
          const form = formsResponse.data[0];

          if (form) {
            const formResponse = await http.get(`/cardfree-api/v1/form/${form.id}`);
            const surveyForm = formResponse.data;

            surveyForm.questions = surveyForm.questions.filter((question) => {
              return question.isActive;
            });

            surveyForm.questions.sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });

            surveyForm.questions.forEach((question) => {
              question.answer = null;

              question.options = question.options.filter((option) => {
                return option.isActive;
              });

              question.normalizedOptions = question.options.map((option) => {
                return {
                  display: option.value,
                  value: option.value
                };
              });
            });

            this.surveyForm = surveyForm;
          }

          else {
            throw new Error('Survey form is not configured for this merchant.');
          }
        }

        catch (error) {
          throw error;
        }
      },

      async submitSurvey() {
        try {
          this.isProcessingSurvey = true;

          const answers = [];

          this.surveyForm.questions.forEach((question) => {
            if (Array.isArray(question.answer)) {
              question.answer.forEach((answer) => {
                answers.push({
                  answer,
                  questionId: question.id
                });
              });
            }

            else {
              answers.push({
                answer: question.answer,
                questionId: question.id
              });
            }
          });

          await http.post(`/cardfree-api/v1/form/${this.surveyForm.id}`, {
            answers,
            orderId: this.$route.query.orderId
          });

          this.surveyWasSubmitted = true;
        }

        catch (error) {
          errorHandlers.generic(error);
        }

        finally {
          this.isProcessingSurvey = false;
        }
      }
    }
  };
</script>
