<template>
  <div class="container container--mobile-max center">
    <div class="section section--loading" v-if="verificationIsProcessing">
      <div class="loader" />
    </div>

    <div class="section" v-else-if="verificationSuccess">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--2 primary">
        {{ content.VerificationSuccess.title }}
      </h1>

      <p class="extra-spacing">{{ content.VerificationSuccess.metadata.subtitle }}</p>

      <a class="button button--large button--auto-width button--primary extra-spacing" :href="merchant.primaryAppUrl" v-if="merchant.primaryAppUrl">{{ content.VerificationSuccess.metadata.cta }}</a>

      <p><help-link /></p>
    </div>

    <div class="section" v-else-if="alreadyActivatedAccount">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--2 primary">
        {{ content.VerificationDuplicateSuccess.title }}
      </h1>

      <p class="extra-spacing">{{ content.VerificationDuplicateSuccess.metadata.subtitle }}</p>

      <a class="button button--large button--auto-width button--primary extra-spacing" :href="merchant.primaryAppUrl" v-if="merchant.primaryAppUrl">{{ content.VerificationDuplicateSuccess.metadata.cta }}</a>

      <p><help-link /></p>
    </div>

    <div class="section" v-else>
      <span class="icon icon--block icon--medium primary fa-exclamation-circle" />

      <h1 class="title title--2 primary">
        {{ content.VerificationFail.title }}
      </h1>

      <p>{{ content.VerificationFail.metadata.subtitle }}</p>

      <p><help-link /></p>
    </div>
  </div>
</template>



<script>
  import http from '@/services/http';
  import logger from '@/services/logger';
  import { mapGetters } from 'vuex';



  export default {
    name: 'EmailVerificationPage',

    metaInfo() {
      return {
        title: 'Email Verification'
      };
    },

    data() {
      return {
        alreadyActivatedAccount: false,
        verificationIsProcessing: false,
        verificationSuccess: false
      };
    },

    computed: {
      ...mapGetters('content', ['content']),
      ...mapGetters('merchant', ['merchant'])
    },

    created() {
      this.createdHandler();
    },

    methods: {
      async createdHandler() {
        this.verifyEmail();
      },

      async verifyEmail() {
        try {
          this.verificationIsProcessing = true;

          await http.put('/cardfree-api/v1/account/email-verification', {
            token: this.$route.params.token
          });

          this.verificationSuccess = true;
        }

        catch (error) {
          logger.error(error);

          try {
            const errorCode = error.response.data.errors[0].code;

            if (['expired-email-verification-token', 'expired-verification-token'].includes(errorCode)) {
              this.$router.push({
                name: 'sendVerificationEmail',
                params: {
                  merchantId: this.$route.params.merchantId
                }
              });
            }

            else if (errorCode === 'already-activated-account') {
              this.alreadyActivatedAccount = true;
            }
          }

          catch (e) {
            logger.error(e);
          }
        }

        this.verificationIsProcessing = false;
      }
    }
  };
</script>
