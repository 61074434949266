import router from '@/router';
import store from '@/store';

import checklistInput from '@/components/globals/checklist-input.vue';
import helpLink from '@/components/globals/help-link.vue';
import inputError from '@/components/globals/input-error.vue';
import radioInput from '@/components/globals/radio-input.vue';
import starRatingInput from '@/components/globals/star-rating-input.vue';
import textInput from '@/components/globals/text-input.vue';
import validatedForm from '@/components/globals/validated-form.vue';

import lowercase from '@/filters/lowercase';

import configureGoogleAnalytics from '@/helpers/configure-google-analytics';
import validateConfirmation from '@/helpers/validate-confirmation';
import validateStrongPassword from '@/helpers/validate-strong-password';

import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';



function components() {
  Vue.component('checklistInput', checklistInput);
  Vue.component('helpLink', helpLink);
  Vue.component('inputError', inputError);
  Vue.component('radioInput', radioInput);
  Vue.component('starRatingInput', starRatingInput);
  Vue.component('textInput', textInput);
  Vue.component('validatedForm', validatedForm);
}



function directives() {}



function filters() {
  Vue.filter('lowercase', lowercase);
}



function mixins() {}



function plugins() {
  store.dispatch('merchant/initialize');
  store.dispatch('content/initialize');

  VeeValidate.Validator.extend('confirmation', validateConfirmation);
  VeeValidate.Validator.extend('strong_password', validateStrongPassword);

  Vue.use(VeeValidate, {
    classes: true,
    events: 'input',
    fastExit: false
  });

  Vue.use(VueMeta);
  Vue.use(VueRouter);

  Vue.use(VueAnalytics, {
    id: window._googleAnalyticsTrackingId,
    router,
    ready: configureGoogleAnalytics
  });
}



const globals = {
  initialize() {
    plugins();
    components();
    directives();
    filters();
    mixins();
  }
};



export default globals;
