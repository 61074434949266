import VueRouter from 'vue-router';

import emailVerification from '@/components/pages/email-verification-page.vue';
import fourOhFour from '@/components/pages/four-oh-four-page.vue';
import sendResetPasswordEmail from '@/components/pages/send-reset-password-email-page.vue';
import sendVerificationEmail from '@/components/pages/send-verification-email-page.vue';
import survey from '@/components/pages/survey-page.vue';
import unsubscribe from '@/components/pages/unsubscribe-page.vue';
import updatePassword from '@/components/pages/update-password-page.vue';



const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/merchant/:merchantId/email-verification/:token',
      name: 'emailVerification',
      component: emailVerification
    },
    {
      path: '/merchant/:merchantId/send-reset-password-email',
      name: 'sendResetPasswordEmail',
      component: sendResetPasswordEmail
    },
    {
      path: '/merchant/:merchantId/send-verification-email',
      name: 'sendVerificationEmail',
      component: sendVerificationEmail
    },
    {
      path: '/merchant/:merchantId/survey/:surveyType',
      name: 'survey',
      component: survey
    },
    {
      path: '/merchant/:merchantId/reset-password',
      name: 'updatePassword',
      component: updatePassword
    },
    {
      path: '/merchant/:merchantId/unsubscribe',
      name: 'unsubscribe',
      component: unsubscribe
    },
    {
      path: '*',
      name: 'fourOhFour',
      component: fourOhFour
    }
  ]
});



export default router;
