<template>
  <div
    class="text-input"
    :class="{
      'text-input--textarea': type === 'textarea',
      'text-input--has-content': !!value || value === 0,
      'text-input--has-icon': type === 'email' || type === 'password'
    }">
    <input
      v-if="type === 'email'"
      class="text-input__input"
      type="email"
      @blur="blurHandler"
      @focus="focusHandler"
      @input="inputHandler"
      @keydown="keydownHandler"
      v-bind="attributes"
      v-validate="allValidations">

    <input
      v-else-if="type === 'number'"
      class="text-input__input"
      type="number"
      @blur="blurHandler"
      @focus="focusHandler"
      @input="inputHandler"
      @keydown="keydownHandler"
      v-bind="attributes"
      v-validate="allValidations">

    <input
      v-else-if="type === 'password'"
      class="text-input__input"
      type="password"
      @blur="blurHandler"
      @focus="focusHandler"
      @input="inputHandler"
      @keydown="keydownHandler"
      v-bind="attributes"
      v-validate="allValidations">

    <input
      v-else-if="type === 'text'"
      class="text-input__input"
      type="text"
      @blur="blurHandler"
      @focus="focusHandler"
      @input="inputHandler"
      @keydown="keydownHandler"
      v-bind="attributes"
      v-validate="allValidations">

    <textarea
      v-else-if="type === 'textarea'"
      class="text-input__input"
      @blur="blurHandler"
      @focus="focusHandler"
      @input="inputHandler"
      @keydown="keydownHandler"
      v-bind="attributes"
      v-validate="allValidations" />

    <label class="text-input__label" :for="attributes.id">
      {{ label }}
    </label>

    <div class="text-input__icon" aria-hidden="true">
      <span
        class="icon"
        :class="{
          'fa-envelope': type === 'email',
          'fa-key': type === 'password'
        }" />
    </div>

    <input-error :error="errors.firstByRule(scopedInputName, 'required') || errors.first(scopedInputName)" />
  </div>
</template>



<script>
  import lowercase from '@/filters/lowercase';



  export default {
    name: 'TextInput',

    props: {
      allowNegative: {
        type: Boolean,
        default: true
      },

      autocomplete: {
        type: String,
        default: null
      },

      id: {
        type: String,
        default: null
      },

      label: {
        type: String,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      pattern: {
        type: String,
        default: null
      },

      placeholder: {
        type: String,
        default: null
      },

      type: {
        type: String,
        required: true
      },

      validations: {
        type: Object,
        default() {
          return {};
        }
      },

      value: {
        type: [String, Number],
        default: null
      }
    },

    data() {
      return {
        internalValue: null,
        isFocused: false
      };
    },

    computed: {
      allValidations: {
        get() {
          const defaults = {};

          if (this.type === 'email') {
            defaults.email = true;
          }

          return Object.assign(defaults, this.validations);
        }
      },

      attributes: {
        get() {
          return {
            autocomplete: this.autocomplete,
            'data-vv-as': lowercase(this.label, true),
            id: this.id || this.name,
            name: this.name,
            pattern: this.pattern,
            placeholder: this.placeholder,
            value: this.internalValue
          };
        }
      },

      scopedInputName: {
        get() {
          if (this.validatedForm.name) {
            return `${this.validatedForm.name}.${this.name}`;
          }

          return this.name;
        }
      }
    },

    watch: {
      value: 'updateInternalValue'
    },

    created() {
      this.createdHandler();
    },

    inject: {
      $validator: {
        from: '$validator'
      },

      validatedForm: {
        from: 'validatedForm',
        default() {
          return {};
        }
      }
    },

    methods: {
      blurHandler(event) {
        this.isFocused = false;

        /*
          NOTE: If a number isn't formatted correctly in the
          input (for examle: "20.2.2" or "1-2.4"), the browser
          will return an empty string as the input value. On
          blur, we'll reset all empty string values to null to
          remove potentially bad input.
        */

        if (event.target.value === '') {
          event.target.value = '';
        }

        this.updateInternalValue(this.value);
      },

      createdHandler() {
        this.updateInternalValue(this.value);
      },

      focusHandler() {
        this.isFocused = true;
      },

      inputHandler(event) {
        let value = event.target.value;

        if (!this.allowNegative) {
          value = value.replace(/-/g, '');
        }

        this.internalValue = value;

        if (event.target.type === 'number') {
          if (value) {
            value = Number(value);
          }

          else {
            value = null;
          }
        }

        this.$emit('input', value);
      },

      keydownHandler(event) {
        if (!this.allowNegative && event.key === '-') {
          event.preventDefault();
        }
      },

      updateInternalValue(modelValue) {
        const modelValueIsDefined = !!modelValue || modelValue === 0;
        const internalValue = this.internalValue;

        const shouldUpdateInternalValue = !this.isFocused || (modelValue !== internalValue);

        if (modelValueIsDefined && shouldUpdateInternalValue) {
          this.internalValue = modelValue;
        }
      }
    }
  };
</script>
