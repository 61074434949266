import logger from '@/services/logger';
import joinPath from '@/helpers/join-path';



export default {
  namespaced: true,

  state: {
    appSettings: {},
    baseUrl: null,
    friendlyUriName: null,
    id: null,
    name: null,
    options: {
      skinOptions: {}
    },
    thirdPartySignIn: {
      enabled: false,
      facebook: false,
      google: false
    }
  },

  getters: {
    merchant(state) {
      const computed = {
        enabledApps: [],
        features: {}
      };

      if (state.appSettings.resources) {
        state.appSettings.resources.forEach((resource) => {
          if (resource.value && resource.value !== 'true' && resource.value !== 'false') {
            computed.features[resource.key] = resource.value;
          }

          else {
            computed.features[resource.key] = resource.value === 'true';
          }
        });
      }

      if (computed.features['order-ahead']) {
        computed.enabledApps.push('order-ahead');
        computed.primaryAppUrl = !computed.features['disable-ordering'] ? joinPath(state.baseUrl, state.friendlyUriName, 'order') : null;
      }
      if (computed.features['order-at-table']) {
        computed.enabledApps.push('order-at-table');
      }
      if (computed.features['pay-at-table']) {
        computed.enabledApps.push('pay-at-table');
      }
      if (computed.features['read-only-menu']) {
        computed.enabledApps.push('read-only-menu');
      }

      computed.shouldOptUserInToEmails = state.options.cardFreeDiscountsEnabled || state.options.cardFreeLoyaltyEnabled || state.options.hasSignUpOffer;
      computed.shouldOptUserInToLoyalty = state.options.cardFreeLoyaltyEnabled || state.options.hasSignUpOffer;

      return {
        ...state,
        ...computed
      };
    }
  },

  actions: {
    initialize({ commit }) {
      try {
        const parser = document.createElement('textarea');
        parser.innerHTML = window._merchant
          .replace(/\n/g, '\\n')
          .replace(/\r/g, '\\r');
        const merchant = JSON.parse(parser.value);
        commit('SET_MERCHANT', merchant);
      }

      catch (error) {
        logger.error(error);

        window._error = true;
      }
    }
  },

  mutations: {
    SET_MERCHANT(state, merchant) {
      state.appSettings = merchant.appSettings || {};
      state.baseUrl = merchant.baseUrl;
      state.friendlyUriName = merchant.friendlyUriName;
      state.id = merchant.merchantId;
      state.name = merchant.name;
      state.options = merchant.options || {};

      const signInTypes = merchant.supportedThirdPartyLoginTypes || [];
      state.thirdPartySignIn.facebook = signInTypes.indexOf('Facebook') > -1;
      state.thirdPartySignIn.google = signInTypes.indexOf('Google') > -1;
      state.thirdPartySignIn.enabled = state.thirdPartySignIn.facebook || state.thirdPartySignIn.google;
    }
  }
};
