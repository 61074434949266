<template>
  <div
    class="checkbox-input"
    :class="{
      'checkbox-input--box': radioStyle === 'box',
      'checkbox-input--default': radioStyle === 'default',
      'checkbox-input--has-label': labelStyle !== 'hidden',
      'checkbox-input--radio': radioStyle === 'default'
    }">
    <template v-if="labelStyle !== 'hidden'">
      <p class="title title--6 half-spacing" v-if="labelStyle === 'small'">
        {{ label }}
      </p>

      <p class="title title--5 half-spacing" v-else>
        {{ label }}
      </p>
    </template>

    <div class="checkbox-input__options">
      <div
        class="checkbox-input__option"
        v-for="(option, index) in options"
        :key="option.value">
        <template v-if="index === 0">
          <input
            class="checkbox-input__input"
            type="radio"
            :name="name"
            :id="name + index"
            :value="option.value"
            :disabled="option.disabled"
            v-model="val"
            v-validate="validations"
            :data-vv-as="label | lowercase(true)"
            @click="optionClickHandler(option)">
        </template>

        <template v-else>
          <input
            class="checkbox-input__input"
            type="radio"
            :name="name"
            :id="name + index"
            :value="option.value"
            :disabled="option.disabled"
            v-model="val"
            @click="optionClickHandler(option)">
        </template>

        <label class="checkbox-input__label" :for="name + index">
          <span class="checkbox-input__label-text">
            {{ option.display }}

            <span class="checkbox-input__label-sub-text" v-if="option.subDisplay">
              {{ option.subDisplay }}
            </span>
          </span>
        </label>
      </div>
    </div>

    <input-error :error="errors.first(scopedInputName) ? 'An option must be selected.' : null" />
  </div>
</template>



<script>
  export default {
    name: 'RadioInput',

    props: {
      allowUncheck: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        required: true
      },

      labelStyle: {
        type: String,
        default: ''
      },

      name: {
        type: String,
        required: true
      },

      options: {
        type: Array,
        required: true
      },

      radioStyle: {
        type: String,
        default: 'default'
      },

      validations: {
        type: Object,
        default() {
          return {};
        }
      },

      value: {
        type: [String, Number, Boolean],
        default: null
      }
    },

    computed: {
      scopedInputName: {
        get() {
          if (this.validatedForm.name) {
            return `${this.validatedForm.name}.${this.name}`;
          }

          return this.name;
        }
      },

      val: {
        get() {
          return this.value;
        },

        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    },

    inject: {
      $validator: {
        from: '$validator'
      },

      validatedForm: {
        from: 'validatedForm',
        default() {
          return {};
        }
      }
    },

    methods: {
      optionClickHandler(option) {
        if (this.allowUncheck && option.value === this.value) {
          this.$emit('input', null);
        }
      }
    }
  };
</script>
