<template>
  <div class="star-rating-input">
    <p class="title title--5">
      {{ label }}
    </p>

    <div class="star-rating-input__options">
      <div
        class="star-rating-input__option"
        :class="{
          'star-rating-input--selected': option <= value
        }"
        v-for="(option, index) in options"
        :key="option">
        <template v-if="index === 0">
          <input
            class="star-rating-input__input"
            type="radio"
            :name="name"
            :id="name + index"
            :value="option"
            v-model="val"
            v-validate="validations"
            :data-vv-as="label | lowercase(true)">
        </template>

        <template v-else>
          <input
            class="star-rating-input__input"
            type="radio"
            :name="name"
            :id="name + index"
            :value="option"
            v-model="val">
        </template>

        <label class="star-rating-input__label" :for="name + index">
          <span class="star-rating-input__unselected-icon icon far fa-star" />
          <span class="star-rating-input__selected-icon icon fa-star" />

          <span class="ada-text">{{ option }} stars</span>
        </label>
      </div>
    </div>

    <input-error :error="errors.first(scopedInputName) ? 'Must select a rating.' : null" />
  </div>
</template>



<script>
  export default {
    name: 'StarRatingInput',

    props: {
      label: {
        type: String,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      validations: {
        type: Object,
        default() {
          return {};
        }
      },

      value: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        options: [1, 2, 3, 4, 5]
      };
    },

    computed: {
      scopedInputName: {
        get() {
          if (this.validatedForm.name) {
            return `${this.validatedForm.name}.${this.name}`;
          }

          return this.name;
        }
      },

      val: {
        get() {
          return this.value;
        },

        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    },

    inject: {
      $validator: {
        from: '$validator'
      },

      validatedForm: {
        from: 'validatedForm',
        default() {
          return {};
        }
      }
    }
  };
</script>
