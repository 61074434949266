import axios from 'axios';
import { v4 as uuid } from 'uuid';
import uaParser from 'ua-parser-js';
import storage from './storage';

export const getDeviceId = () => {
  const existingId = storage.local.get('deviceId');

  if (existingId) {
    return existingId;
  }
  else {
    const newId = uuid();

    storage.local.set('deviceId', newId);

    return newId;
  }
};

export const getHttpHeaders = () => {
  const { os, device } = uaParser(window.navigator.userAgent);

  return {
    'device-id': getDeviceId(),
    'device-model': device.model,
    'device-os': os.name,
    'device-os-version': os.version
  };
};

export const requestInterceptor = (config) => {
  config.headers['x-correlation-id'] = uuid();

  return config;
};

export const getAxiosConfig = () => {
  const axiosConfig = {
    timeout: 20000,
    headers: getHttpHeaders()
  };

  return axiosConfig;
};

export const httpService = () => {
  /** https://axios-http.com/docs/instance */
  const http = axios.create(getAxiosConfig());

  /** https://axios-http.com/docs/interceptors */
  http.interceptors.request.use(requestInterceptor);

  return http;
};

const http = httpService();

export default http;
