<template>
  <div class="section center">
    <span class="icon icon--block icon--large secondary fa-compass" />

    <h1 class="title title--1">
      404
    </h1>

    <p>
      This page doesn't exist.
    </p>
  </div>
</template>



<script>
  export default {
    name: 'FourOhFourPage',

    metaInfo() {
      return {
        title: '404'
      };
    }
  };
</script>
