<template>
  <div
    class="checkbox-input checkbox-input--default"
    :class="{
      'checkbox-input--has-label': labelStyle !== 'hidden'
    }">
    <template v-if="labelStyle !== 'hidden'">
      <p class="title title--6 half-spacing" v-if="labelStyle === 'small'">
        {{ label }}
      </p>

      <p class="title title--5 half-spacing" v-else>
        {{ label }}
      </p>
    </template>

    <div class="checkbox-input__options">
      <div
        class="checkbox-input__option"
        v-for="(option, index) in options"
        :key="option.value">
        <template v-if="index === 0">
          <input
            class="checkbox-input__input"
            type="checkbox"
            :name="name"
            :id="name + index"
            :disabled="option.disabled"
            v-model="selectedValues[index]"
            v-validate="{
              required: isRequired
            }"
            :data-vv-as="label | lowercase(true)">
        </template>

        <template v-else>
          <input
            class="checkbox-input__input"
            type="checkbox"
            :name="name + index"
            :id="name + index"
            :disabled="option.disabled"
            v-model="selectedValues[index]"
            v-validate="{
              required: isRequired
            }">
        </template>

        <label class="checkbox-input__label" :for="name + index">
          <span class="checkbox-input__label-text">
            {{ option.display }}

            <span class="checkbox-input__label-sub-text" v-if="option.subDisplay">
              {{ option.subDisplay }}
            </span>
          </span>
        </label>
      </div>
    </div>

    <input-error :error="errors.first(scopedInputName) ? 'An option must be selected.' : null" />
  </div>
</template>



<script>
  export default {
    name: 'ChecklistInput',

    props: {
      label: {
        type: String,
        required: true
      },

      labelStyle: {
        type: String,
        default: ''
      },

      name: {
        type: String,
        required: true
      },

      options: {
        type: Array,
        required: true
      },

      validations: {
        type: Object,
        default() {
          return {};
        }
      },

      value: {
        type: Array,
        default() {
          return [];
        }
      }
    },

    data() {
      return {
        selectedValues: {}
      };
    },

    computed: {
      isRequired() {
        return this.validations.required && (!this.value || this.value.length === 0);
      },

      scopedInputName() {
        if (this.validatedForm.name) {
          return `${this.validatedForm.name}.${this.name}`;
        }

        return this.name;
      }
    },

    inject: {
      $validator: {
        from: '$validator'
      },

      validatedForm: {
        from: 'validatedForm',
        default() {
          return {};
        }
      }
    },

    watch: {
      selectedValues: {
        handler: 'selectedValuesChangeHandler',
        deep: true,
        immediate: true
      }
    },

    methods: {
      selectedValuesChangeHandler(values) {
        const indexes = Object.keys(values);
        const result = [];

        indexes.forEach((index) => {
          if (values[index]) {
            result.push(this.options[index].value);
          }
        });

        this.$emit('input', result);
      }
    }
  };
</script>
