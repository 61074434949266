<template>
  <form
    class="form"
    :class="{
      'form--disabled': disabled,
      'form--dirty': dirty,
      'form--submit-attempted': submitAttempted
    }"
    :id="name"
    @submit="submit"
    @input="markFormAsDirty"
    @change="markFormAsDirty"
    :data-vv-scope="name"
    novalidate
    autocomplete="off">
    <fieldset class="form__fieldset" :disabled="disabled">
      <slot />
    </fieldset>
  </form>
</template>



<script>
  import events from '@/services/events';



  export default {
    name: 'ValidatedForm',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },

      disableAutoFocus: {
        type: Boolean,
        default: false
      },

      name: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        dirty: false,
        submitAttempted: false
      };
    },

    inject: {
      $validator: {
        from: '$validator'
      }
    },

    provide() {
      const name = this.name;

      return {
        validatedForm: {
          name
        }
      };
    },

    mounted() {
      this.focusFirstInput();
    },

    created() {
      events.$on('validated-form:invalid-submit', this.handleOutsideInvalidSubmit);
    },

    destroyed() {
      events.$off('validated-form:invalid-submit', this.handleOutsideInvalidSubmit);
    },

    methods: {
      focusFirstInput() {
        let firstInput = this.$el.querySelector('input:not([type="hidden"]):not([tabindex="-1"]), select');
        const firstButton = this.$el.querySelector('button');

        if (!this.disableAutoFocus) {
          if (firstInput) {
            if (firstInput.type === 'radio') {
              const checkedRadio = this.$el.querySelector('input:checked');

              if (checkedRadio) {
                firstInput = checkedRadio;
              }
            }

            firstInput.focus();
          }

          else if (firstButton) {
            firstButton.focus();
          }
        }
      },

      handleOutsideInvalidSubmit(formName) {
        if (this.name === formName) {
          this.submitAttempted = true;

          this.validationHandler(false);
        }
      },

      markFormAsDirty() {
        this.dirty = true;
      },

      submit(event) {
        this.submitAttempted = true;

        event.preventDefault();

        this.$validator.validateAll(this.name).then(this.validationHandler);
      },

      validationHandler(isValid) {
        if (isValid) {
          this.$emit('valid-submit');
        }

        else {
          const firstInvalidInput = this.$el.querySelector('[aria-invalid="true"], .invalid');

          if (firstInvalidInput.classList.contains('select-input__hidden')) {
            const input = firstInvalidInput.parentNode.querySelector('.text-input__input');

            input.focus();
          }

          else if (firstInvalidInput && firstInvalidInput.getAttribute('tabindex') !== '-1') {
            firstInvalidInput.focus();
          }
        }
      }
    }
  };
</script>
