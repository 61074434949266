var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-input",class:{
    'text-input--textarea': _vm.type === 'textarea',
    'text-input--has-content': !!_vm.value || _vm.value === 0,
    'text-input--has-icon': _vm.type === 'email' || _vm.type === 'password'
  }},[(_vm.type === 'email')?_c('input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(_vm.allValidations),expression:"allValidations"}],staticClass:"text-input__input",attrs:{"type":"email"},on:{"blur":_vm.blurHandler,"focus":_vm.focusHandler,"input":_vm.inputHandler,"keydown":_vm.keydownHandler}},'input',_vm.attributes,false)):(_vm.type === 'number')?_c('input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(_vm.allValidations),expression:"allValidations"}],staticClass:"text-input__input",attrs:{"type":"number"},on:{"blur":_vm.blurHandler,"focus":_vm.focusHandler,"input":_vm.inputHandler,"keydown":_vm.keydownHandler}},'input',_vm.attributes,false)):(_vm.type === 'password')?_c('input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(_vm.allValidations),expression:"allValidations"}],staticClass:"text-input__input",attrs:{"type":"password"},on:{"blur":_vm.blurHandler,"focus":_vm.focusHandler,"input":_vm.inputHandler,"keydown":_vm.keydownHandler}},'input',_vm.attributes,false)):(_vm.type === 'text')?_c('input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(_vm.allValidations),expression:"allValidations"}],staticClass:"text-input__input",attrs:{"type":"text"},on:{"blur":_vm.blurHandler,"focus":_vm.focusHandler,"input":_vm.inputHandler,"keydown":_vm.keydownHandler}},'input',_vm.attributes,false)):(_vm.type === 'textarea')?_c('textarea',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(_vm.allValidations),expression:"allValidations"}],staticClass:"text-input__input",on:{"blur":_vm.blurHandler,"focus":_vm.focusHandler,"input":_vm.inputHandler,"keydown":_vm.keydownHandler}},'textarea',_vm.attributes,false)):_vm._e(),_c('label',{staticClass:"text-input__label",attrs:{"for":_vm.attributes.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"text-input__icon",attrs:{"aria-hidden":"true"}},[_c('span',{staticClass:"icon",class:{
        'fa-envelope': _vm.type === 'email',
        'fa-key': _vm.type === 'password'
      }})]),_c('input-error',{attrs:{"error":_vm.errors.firstByRule(_vm.scopedInputName, 'required') || _vm.errors.first(_vm.scopedInputName)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }