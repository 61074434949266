<template>
  <div class="container container--mobile-max center">
    <div class="section" v-if="updatePasswordSuccess">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <template v-if="$route.query.created">
        <h1 class="title title--2 primary">
          {{ content.CreatePasswordSuccess.title }}
        </h1>

        <p class="extra-spacing">{{ content.CreatePasswordSuccess.metadata.subtitle }}</p>

        <a class="button button--large button--auto-width button--primary extra-spacing" :href="merchant.primaryAppUrl" v-if="merchant.primaryAppUrl">{{ content.CreatePasswordSuccess.metadata.cta }}</a>
      </template>

      <template v-else>
        <h1 class="title title--2 primary">
          {{ content.UpdatePasswordSuccess.title }}
        </h1>

        <p class="extra-spacing">{{ content.UpdatePasswordSuccess.metadata.subtitle }}</p>

        <a class="button button--large button--auto-width button--primary extra-spacing" :href="merchant.primaryAppUrl" v-if="merchant.primaryAppUrl">{{ content.UpdatePasswordSuccess.metadata.cta }}</a>
      </template>

      <p><help-link /></p>
    </div>



    <validated-form
      class="section"
      name="updatePassword"
      @valid-submit="updatePassword"
      v-else>
      <div class="extra-spacing">
        <template v-if="$route.query.created">
          <h1 class="title title--2 primary">
            {{ content.CreatePassword.title }}
          </h1>

          <p>{{ content.CreatePassword.metadata.subtitle }}</p>
        </template>

        <template v-else>
          <h1 class="title title--2 primary">
            {{ content.PasswordReset.title }}
          </h1>

          <p>{{ content.PasswordReset.metadata.subtitle }}</p>
        </template>
      </div>

      <text-input
        type="password"
        label="New Password"
        name="password"
        v-model="password"
        :validations="{
          required: true,
          strong_password: true
        }" />

      <text-input
        type="password"
        label="Confirm New Password"
        name="passwordConfirm"
        v-model="passwordConfirm"
        :validations="{
          required: true,
          confirmation: password
        }" />

      <button
        class="button button--primary button--large extra-spacing-top extra-spacing"
        :class="{
          'button--loading': updatePasswordIsLoading
        }"
        type="submit">
        {{ $route.query.created ? content.CreatePassword.metadata.cta : content.PasswordReset.metadata.cta }}
      </button>

      <help-link />
    </validated-form>
  </div>
</template>



<script>
  import errorHandlers from '@/services/error-handlers';
  import http from '@/services/http';
  import logger from '@/services/logger';
  import { mapGetters } from 'vuex';



  export default {
    name: 'UpdatePasswordPage',

    metaInfo() {
      return {
        title: 'Reset Password'
      };
    },

    data() {
      return {
        password: null,
        passwordConfirm: null,
        updatePasswordIsLoading: false,
        updatePasswordSuccess: false
      };
    },

    computed: {
      ...mapGetters('content', ['content']),
      ...mapGetters('merchant', ['merchant'])
    },

    methods: {
      async updatePassword() {
        try {
          this.updatePasswordIsLoading = true;

          await http.put('/cardfree-api/v1/account/password', {
            password: this.password,
            scope: this.$route.query.scope,
            token: this.$route.query.token
          });

          this.updatePasswordSuccess = true;
        }

        catch (error) {
          if (error.response && error.response.status === 403) {
            logger.error(error);
            this.$router.push({
              name: 'sendResetPasswordEmail',
              params: {
                merchantId: this.$route.params.merchantId
              },
              query: {
                expired: true
              }
            });
          }

          else {
            errorHandlers.generic(error, {
              fallbackMessage: 'Failed to update password'
            });
          }
        }

        this.updatePasswordIsLoading = false;
      }
    }
  };
</script>
