/*
  NOTE: This helper exports a custom validation rule for the Vee Validate plugin:
  https://baianat.github.io/vee-validate/guide/custom-rules.html
*/



const validateConfirmation = {
  getMessage() {
    return 'Confirmation does not match.';
  },

  validate(value, args) {
    return value === args[0];
  }
};



export default validateConfirmation;
