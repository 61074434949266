<template>
  <div class="container container--mobile-max center">
    <div class="section section--loading" v-if="updateIsProcessing">
      <div class="loader" />
    </div>

    <div class="section" v-else-if="unsubscribeSuccess">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--2 primary">
        {{ content.UnsubscribeSuccess.title }}
      </h1>

      <p class="bigger">
        {{ content.UnsubscribeSuccess.metadata.subtitle }}
      </p>

      <p>
        <button class="link" type="button" @click="updateSubscription(true)">
          Join Back In
        </button>
      </p>
    </div>

    <div class="section" v-else-if="resubscribeSuccess">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--2 primary">
        {{ content.ResubscribeSuccess.title }}
      </h1>

      <p class="bigger">
        {{ content.ResubscribeSuccess.metadata.subtitle }}
      </p>

      <p>
        <button class="link" type="button" @click="updateSubscription(false)">
          Unsubscribe
        </button>
      </p>
    </div>

    <div class="section" v-else>
      <span class="icon icon--block icon--medium primary fa-exclamation-circle" />

      <h1 class="title title--2 primary">
        {{ content.UnsubscribeFail.title }}
      </h1>

      <p>{{ content.UnsubscribeFail.metadata.subtitle }}</p>

      <p><help-link /></p>
    </div>
  </div>
</template>



<script>
  import http from '@/services/http';
  import logger from '@/services/logger';
  import { mapGetters } from 'vuex';



  export default {
    name: 'UnsubscribePage',

    metaInfo() {
      return {
        title: 'Unsubscribe'
      };
    },

    data() {
      return {
        resubscribeSuccess: false,
        unsubscribeSuccess: false,
        updateIsProcessing: false
      };
    },

    computed: {
      ...mapGetters('content', ['content'])
    },

    created() {
      this.createdHandler();
    },

    methods: {
      async createdHandler() {
        this.updateSubscription(false);
      },

      async updateSubscription(optIn) {
        try {
          this.updateIsProcessing = true;
          this.unsubscribeSuccess = false;
          this.resubscribeSuccess = false;

          await http.put('/cardfree-api/v1/account/me/email-opt-in', {
            email: this.$route.query.email,
            optIn
          });

          if (optIn) {
            this.resubscribeSuccess = true;
          }

          else {
            this.unsubscribeSuccess = true;
          }
        }

        catch (error) {
          logger.error(error);
        }

        this.updateIsProcessing = false;
      }
    }
  };
</script>
