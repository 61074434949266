<template>
  <div class="container container--mobile-max center">
    <div class="section" v-if="sendResetPasswordEmailSuccess">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--2 primary">
        Success!
      </h1>

      <p>If we find your email, you will receive an email in the next few minutes to reset your password.</p>

      <p><help-link /></p>
    </div>



    <validated-form
      class="section"
      name="sendResetPasswordEmail"
      @valid-submit="sendResetPasswordEmail"
      v-else>
      <div class="extra-spacing">
        <template v-if="$route.query.expired">
          <span class="icon icon--block icon--medium primary fa-exclamation-circle" />

          <h1 class="title title--2 primary">
            Reset Link Expired
          </h1>

          <p>Sorry, your password reset link has expired. We can send you a new link, but hurry - it will only be available for a limited amount of time. Please enter the email you use for your account:</p>
        </template>

        <template v-else-if="$route.query.created">
          <h1 class="title title--2 primary">
            {{ content.SendCreatePasswordEmail.title }}
          </h1>

          <p>{{ content.SendCreatePasswordEmail.metadata.subtitle }}</p>
        </template>

        <template v-else>
          <h1 class="title title--2 primary">
            {{ content.SendPasswordResetEmail.title }}
          </h1>

          <p>{{ content.SendPasswordResetEmail.metadata.subtitle }}</p>
        </template>
      </div>

      <text-input
        type="email"
        label="Email"
        name="email"
        v-model="email"
        :validations="{
          required: true
        }" />

      <button
        class="button button--primary button--large extra-spacing-top extra-spacing"
        :class="{
          'button--loading': sendResetPasswordEmailIsLoading
        }"
        type="submit">
        {{ $route.query.created ? content.SendCreatePasswordEmail.metadata.cta : content.SendPasswordResetEmail.metadata.cta }}
      </button>

      <help-link />
    </validated-form>
  </div>
</template>



<script>
  import events from '@/services/events';
  import http from '@/services/http';
  import logger from '@/services/logger';
  import { mapGetters } from 'vuex';



  export default {
    name: 'SendResetPasswordEmailPage',

    metaInfo() {
      return {
        title: 'Reset Password'
      };
    },

    data() {
      return {
        email: null,
        sendResetPasswordEmailIsLoading: false,
        sendResetPasswordEmailSuccess: false
      };
    },

    computed: {
      ...mapGetters('content', ['content'])
    },

    created() {
      this.createdHandler();
    },

    methods: {
      createdHandler() {
        this.email = this.$route.query.email || null;
      },

      async sendResetPasswordEmail() {
        try {
          this.sendResetPasswordEmailIsLoading = true;

          await http.post('/cardfree-api/v1/account/password/reset', {
            createdAccount: this.$route.query.created === 'true',
            emailAddress: this.email
          });

          this.sendResetPasswordEmailSuccess = true;
        }

        catch (error) {
          logger.error(error);

          events.$emit('notification', {
            type: 'error',
            message: 'Failed to send password reset email.'
          });
        }

        this.sendResetPasswordEmailIsLoading = false;
      }
    }
  };
</script>
