<template>
  <div class="container container--mobile-max center">
    <div class="section" v-if="sendVerificationEmailSuccess">
      <span class="icon icon--block icon--medium primary fa-check-circle" />

      <h1 class="title title--2 primary">
        {{ content.SendVerificationEmailSuccess.title }}
      </h1>

      <p>{{ content.SendVerificationEmailSuccess.metadata.subtitle }}</p>

      <p><help-link /></p>
    </div>



    <validated-form
      class="section"
      name="sendVerificationEmail"
      @valid-submit="sendVerificationEmail"
      v-else>
      <div class="extra-spacing">
        <span class="icon icon--block icon--medium primary fa-exclamation-circle" />

        <h1 class="title title--2 primary">
          {{ content.VerificationLinkExpired.title }}
        </h1>

        <p>{{ content.VerificationLinkExpired.metadata.subtitle }}</p>
      </div>

      <text-input
        type="email"
        label="Email"
        name="email"
        v-model="email"
        :validations="{
          required: true
        }" />

      <button
        class="button button--primary button--large extra-spacing-top extra-spacing"
        :class="{
          'button--loading': sendVerificationEmailIsLoading
        }"
        type="submit">
        Resend Link
      </button>

      <help-link />
    </validated-form>
  </div>
</template>



<script>
  import events from '@/services/events';
  import http from '@/services/http';
  import logger from '@/services/logger';
  import { mapGetters } from 'vuex';



  export default {
    name: 'SendVerificationEmailPage',

    metaInfo() {
      return {
        title: 'Email Verification'
      };
    },

    data() {
      return {
        email: null,
        sendVerificationEmailIsLoading: false,
        sendVerificationEmailSuccess: false
      };
    },

    computed: {
      ...mapGetters('content', ['content'])
    },

    methods: {
      async sendVerificationEmail() {
        try {
          this.sendVerificationEmailIsLoading = true;

          await http.put('/cardfree-api/v1/account/email-verification/resend', {
            emailAddress: this.email
          });

          this.sendVerificationEmailSuccess = true;
        }

        catch (error) {
          logger.error(error);

          events.$emit('notification', {
            type: 'error',
            message: 'Failed to send verification email.'
          });

          this.sendVerificationEmailSuccess = true;
        }

        this.sendVerificationEmailIsLoading = false;
      }
    }
  };
</script>
