/*
  NOTE: This helper exports a custom validation rule for the Vee Validate plugin:
  https://baianat.github.io/vee-validate/guide/custom-rules.html
*/



const validateCvv = {
  getMessage(field, params, data) {
    return data.message;
  },

  validate(rawValue) {
    const value = rawValue || '';
    const validationObj = {
      valid: true,
      data: {}
    };
    const hasEightCharacters = value.length >= 8;
    const hasLowercase = /[a-z]/.test(value);
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);

    if (!hasEightCharacters) {
      validationObj.valid = false;
      validationObj.data.message = 'Must contain at least 8 characters.';
    }

    else if (!hasLowercase) {
      validationObj.valid = false;
      validationObj.data.message = 'Must contain at least 1 lowercase letter.';
    }

    else if (!hasUppercase) {
      validationObj.valid = false;
      validationObj.data.message = 'Must contain at least 1 uppercase letter.';
    }

    else if (!hasNumber) {
      validationObj.valid = false;
      validationObj.data.message = 'Must contain at least 1 number.';
    }

    return validationObj;
  }
};



export default validateCvv;
