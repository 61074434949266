var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container max-width-mobile"},[(_vm.isLoading)?_c('div',{staticClass:"center"},[_c('div',{staticClass:"loader"})]):(_vm.surveyWasSubmitted)?_c('div',{staticClass:"section center"},[_c('span',{staticClass:"icon icon--block icon--medium primary fa-check-circle"}),_c('h1',{staticClass:"title title--3 primary"},[_vm._v(" Thank you for taking the survey. We really appreciate your time! ")]),_c('p',[_vm._v("You can close this page now.")])]):_c('validated-form',{attrs:{"name":"survey","disabled":_vm.isProcessingSurvey},on:{"valid-submit":_vm.submitSurvey}},[_c('div',{staticClass:"section center"},[_c('h1',{staticClass:"title title--2 primary"},[_vm._v(" "+_vm._s(_vm.surveyForm.title || 'Customer Survey')+" ")]),(_vm.failedToLoad)?_c('p',{staticClass:"message message--danger"},[_vm._v(" Oops! There was a problem loading the survey form. Please try again later. ")]):_c('p',[_vm._v(" "+_vm._s(_vm.surveyForm.description || 'Thank you for being willing to give us feedback. Please answer the following questions:')+" ")])]),(!_vm.isLoading && !_vm.failedToLoad)?[_vm._l((_vm.surveyForm.questions),function(question){return _c('div',{key:question.id,staticClass:"section"},[(question.questionType === 'Checklist' || question.questionType === 'MultipleSelect')?_c('checklist-input',{attrs:{"label":question.label,"name":'surveyQuestion' + question.id,"options":question.normalizedOptions,"validations":{
            required: question.required
          }},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}}):(question.questionType === 'RadioList')?_c('radio-input',{attrs:{"label":question.label,"name":'surveyQuestion' + question.id,"options":question.normalizedOptions,"validations":{
            required: question.required
          }},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}}):(question.questionType === 'StarRating')?_c('star-rating-input',{attrs:{"label":question.label,"name":'surveyQuestion' + question.id,"validations":{
            required: question.required
          }},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}}):(question.questionType === 'Textarea')?_c('text-input',{attrs:{"type":"textarea","label":question.label,"name":'surveyQuestion' + question.id,"validations":{
            required: question.required
          }},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}}):_c('text-input',{attrs:{"type":"text","label":question.label,"name":'surveyQuestion' + question.id,"validations":{
            required: question.required
          }},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})],1)}),_c('div',{staticClass:"section section--alt-background"},[_c('button',{staticClass:"button button--primary button--large",class:{
            'button--loading': _vm.isProcessingSurvey
          },attrs:{"type":"submit"}},[_vm._v(" Submit Survey ")])])]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }