var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.middlewareError)?_c('div',[_vm._m(0)]):_c('div',[_c('div',{staticClass:"notifications"},[_c('div',{staticClass:"notifications__wrapper"},[_c('transition-group',{attrs:{"name":"notification","tag":"div"}},_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id,staticClass:"notifications__message",class:{
            'notifications__message--danger': notification.type === 'error',
            'notifications__message--info': notification.type === 'info',
            'notifications__message--success': notification.type === 'success'
          }},[_c('p',{staticClass:"notifications__content"},[(notification.subject)?_c('b',[_vm._v(" "+_vm._s(notification.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.message)+" ")]),(notification.clickToDismiss)?_c('button',{staticClass:"button button--small",attrs:{"type":"button"},on:{"click":function($event){return _vm.dismissNotification(notification)}}},[_vm._v(" OK ")]):_vm._e()])}),0)],1)]),_c('div',{staticClass:"container container--wrapper"},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"header__container"},[_c('div',{staticClass:"header__logo-wrapper",class:{
            'header__logo-wrapper-clickable': _vm.merchant.primaryAppUrl
          },on:{"click":_vm.logoSingleClickHandler}},[(_vm.merchant.options.logoUrl)?[_c('span',{staticClass:"header__logo",style:({
                'background-image': 'url(' + _vm.merchant.options.logoUrl + ')'
              })}),_c('span',{staticClass:"ada-text"},[_vm._v(_vm._s(_vm.merchant.name))])]:_c('h1',{staticClass:"header__logo-text"},[_vm._v(_vm._s(_vm.merchant.name))])],2)])]),_c('div',{staticClass:"container__primary"},[_c('router-view')],1),_c('footer',{staticClass:"footer"},[(_vm.merchant.options.footerDetails.length > 0)?_c('div',{staticClass:"footer__links"},_vm._l((_vm.merchant.options.footerDetails),function(item,index){return (item.link)?_c('a',{key:index,staticClass:"link",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.text))]):_vm._e()}),0):_vm._e(),_vm._l((_vm.merchant.options.footerDetails),function(item,index){return (!item.link)?_c('p',{key:index,staticClass:"smaller"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()}),_vm._m(1)],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-align"},[_c('div',{staticClass:"section center"},[_c('span',{staticClass:"icon icon--block icon--large danger fa-exclamation-circle"}),_c('h1',{staticClass:"title title--1"},[_vm._v(" Oops! ")]),_c('p',[_vm._v(" Either this page doesn't exist or something went wrong. "),_c('br'),_vm._v(" Please try again later. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"smaller"},[_vm._v(" Powered by "),_c('a',{staticClass:"link",attrs:{"href":"https://cardfree.com/","target":"_blank"}},[_vm._v("Cardfree")])])}]

export { render, staticRenderFns }