import Vue from 'vue';
import Vuex from 'vuex';
import content from '@/store/content';
import merchant from '@/store/merchant';



Vue.use(Vuex);



const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production', // We only enable strict mode for development due to slight performance hit
  modules: {
    content,
    merchant
  }
});



export default store;
