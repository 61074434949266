<template>
  <div v-if="middlewareError">
    <div class="vertical-align">
      <div class="section center">
        <span class="icon icon--block icon--large danger fa-exclamation-circle" />
        <h1 class="title title--1">
          Oops!
        </h1>

        <p>
          Either this page doesn't exist or something went wrong.
          <br>
          Please try again later.
        </p>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="notifications">
      <div class="notifications__wrapper">
        <transition-group name="notification" tag="div">
          <div
            class="notifications__message"
            v-for="notification in notifications"
            :key="notification.id"
            :class="{
              'notifications__message--danger': notification.type === 'error',
              'notifications__message--info': notification.type === 'info',
              'notifications__message--success': notification.type === 'success'
            }">
            <p class="notifications__content">
              <b v-if="notification.subject">
                {{ notification.subject }}
              </b>
              {{ notification.message }}
            </p>

            <button
              class="button button--small"
              type="button"
              @click="dismissNotification(notification)"
              v-if="notification.clickToDismiss">
              OK
            </button>
          </div>
        </transition-group>
      </div>
    </div>

    <div class="container container--wrapper">
      <header class="header">
        <div class="header__container">
          <div
            class="header__logo-wrapper"
            :class="{
              'header__logo-wrapper-clickable': merchant.primaryAppUrl
            }"
            @click="logoSingleClickHandler">
            <template v-if="merchant.options.logoUrl">
              <span
                class="header__logo"
                :style="{
                  'background-image': 'url(' + merchant.options.logoUrl + ')'
                }" />
              <span class="ada-text">{{ merchant.name }}</span>
            </template>

            <h1 class="header__logo-text" v-else>{{ merchant.name }}</h1>
          </div>
        </div>
      </header>

      <div class="container__primary">
        <router-view />
      </div>

      <footer class="footer">
        <div class="footer__links" v-if="merchant.options.footerDetails.length > 0">
          <a
            class="link"
            :href="item.link"
            target="_blank"
            v-for="(item, index) in merchant.options.footerDetails"
            :key="index"
            v-if="item.link">{{ item.text }}</a>
        </div>

        <p
          class="smaller"
          v-for="(item, index) in merchant.options.footerDetails"
          :key="index"
          v-if="!item.link">
          {{ item.text }}
        </p>

        <p class="smaller">
          Powered by <a class="link" href="https://cardfree.com/" target="_blank">Cardfree</a>
        </p>
      </footer>
    </div>
  </div>
</template>



<script>
  import events from '@/services/events';
  import logger from '@/services/logger';
  import { mapGetters } from 'vuex';
  import { v4 as uuid } from 'uuid';



  export default {
    name: 'Layout',

    data() {
      return {
        middlewareError: false,
        notifications: []
      };
    },

    computed: {
      ...mapGetters('merchant', ['merchant'])
    },

    created() {
      events.$on('notification', this.displayNotification);

      this.detectTouchDevice();
      this.displayMiddlewareError();
    },

    methods: {
      detectTouchDevice() {
        const isTouchDevice = 'ontouchstart' in window;

        if (isTouchDevice) {
          document.body.classList.add('touch-device');
        }
      },

      dismissNotification(notification) {
        this.notifications = this.notifications.filter((n) => {
          return n.id !== notification.id;
        });
      },

      displayMiddlewareError() {
        const error = window._error;

        if (error) {
          logger.error(new Error('Merchant info failed to load.'));
          this.middlewareError = true;
        }
      },

      displayNotification(notification) {
        notification.id = uuid();

        if (notification.clickToDismiss) {
          const existingNotification = this.notifications.find((n) => {
            return n.message === notification.message;
          });

          if (!existingNotification) {
            this.notifications.push(notification);
          }
        }

        else {
          this.notifications.push(notification);

          setTimeout(() => {
            this.dismissNotification(notification);
          }, 2500);
        }
      },

      logoSingleClickHandler(event) {
        if (!this.merchant.primaryAppUrl) {
          return;
        }

        if (event.metaKey || event.ctrlKey) {
          window.open(this.merchant.primaryAppUrl, '_blank');
        }

        else {
          window.open(this.merchant.primaryAppUrl, '_self');
        }
      }
    }
  };
</script>



<style lang="sass">
  @import '../../../styles/main'
</style>
