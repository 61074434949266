import errorHandlers from '@/services/error-handlers';
import http from '@/services/http';



export default {
  namespaced: true,

  state: {
    content: {
      CreatePassword: {
        metadata: {}
      },
      CreatePasswordSuccess: {
        metadata: {}
      },
      PasswordReset: {
        metadata: {}
      },
      ResubscribeSuccess: {
        metadata: {}
      },
      SendCreatePasswordEmail: {
        metadata: {}
      },
      SendPasswordResetEmail: {
        metadata: {}
      },
      SendVerificationEmailSuccess: {
        metadata: {}
      },
      UnsubscribeFail: {
        metadata: {}
      },
      UnsubscribeSuccess: {
        metadata: {}
      },
      UpdatePasswordSuccess: {
        metadata: {}
      },
      VerificationDuplicateSuccess: {
        metadata: {}
      },
      VerificationFail: {
        metadata: {}
      },
      VerificationLinkExpired: {
        metadata: {}
      },
      VerificationSuccess: {
        metadata: {}
      }
    },
    terms: {
      privacyPolicy: null,
      termsOfService: null
    }
  },

  getters: {
    content(state) {
      return state.content;
    },

    terms(state) {
      return state.terms;
    }
  },

  actions: {
    initialize({ dispatch }) {
      dispatch('loadTerms');
      dispatch('updateContent');
    },

    async loadTerms({ commit }) {
      try {
        const response = await http.get('/cardfree-api/v1/merchant/terms-of-service');
        commit('SET_TERMS', response.data);
      }

      catch (error) {
        errorHandlers.silent(error);
      }
    },

    async updateContent({ commit }) {
      try {
        const response = await http.get('/cardfree-api/v1/content');

        const content = {};

        response.data.forEach((c) => {
          content[c.contentType] = c;
        });

        commit('SET_CONTENT', content);
      }

      catch (error) {
        commit('SET_LOCATION_ID', null);
        errorHandlers.silent(error);
      }
    }
  },

  mutations: {
    SET_CONTENT(state, content) {
      state.content = {
        ...state.content,
        ...content
      };
    },

    SET_LOCATION_ID(state, id) {
      state.locationId = id;
    },

    SET_TERMS(state, terms) {
      state.terms = terms;
    }
  }
};
