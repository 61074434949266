<template>
  <a
    v-if="merchantSupportLinkddress || merchantSupportEmailAddress"
    id="help-link"
    class="link link--margin"
    :href="href"
    target="_blank">
    Need help?
  </a>
</template>


<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'HelpLink',

    computed: {
      ...mapGetters('merchant', ['merchant']),

      merchantSupportLinkddress() {
        return this.merchant.options.supportLinkAddress?.trim();
      },

      merchantSupportEmailAddress() {
        return this.merchant.options.supportEmailAddress?.trim();
      },

      href() {
        if (this.merchantSupportLinkddress) return this.merchantSupportLinkddress;

        if (this.merchantSupportEmailAddress) return `mailto:${this.merchant.options.supportEmailAddress}?subject=Problem Signing in to Account`;

        return '';
      }
    }
  };
</script>
