var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container container--mobile-max center"},[(_vm.updatePasswordSuccess)?_c('div',{staticClass:"section"},[_c('span',{staticClass:"icon icon--block icon--medium primary fa-check-circle"}),(_vm.$route.query.created)?[_c('h1',{staticClass:"title title--2 primary"},[_vm._v(" "+_vm._s(_vm.content.CreatePasswordSuccess.title)+" ")]),_c('p',{staticClass:"extra-spacing"},[_vm._v(_vm._s(_vm.content.CreatePasswordSuccess.metadata.subtitle))]),(_vm.merchant.primaryAppUrl)?_c('a',{staticClass:"button button--large button--auto-width button--primary extra-spacing",attrs:{"href":_vm.merchant.primaryAppUrl}},[_vm._v(_vm._s(_vm.content.CreatePasswordSuccess.metadata.cta))]):_vm._e()]:[_c('h1',{staticClass:"title title--2 primary"},[_vm._v(" "+_vm._s(_vm.content.UpdatePasswordSuccess.title)+" ")]),_c('p',{staticClass:"extra-spacing"},[_vm._v(_vm._s(_vm.content.UpdatePasswordSuccess.metadata.subtitle))]),(_vm.merchant.primaryAppUrl)?_c('a',{staticClass:"button button--large button--auto-width button--primary extra-spacing",attrs:{"href":_vm.merchant.primaryAppUrl}},[_vm._v(_vm._s(_vm.content.UpdatePasswordSuccess.metadata.cta))]):_vm._e()],_c('p',[_c('help-link')],1)],2):_c('validated-form',{staticClass:"section",attrs:{"name":"updatePassword"},on:{"valid-submit":_vm.updatePassword}},[_c('div',{staticClass:"extra-spacing"},[(_vm.$route.query.created)?[_c('h1',{staticClass:"title title--2 primary"},[_vm._v(" "+_vm._s(_vm.content.CreatePassword.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.content.CreatePassword.metadata.subtitle))])]:[_c('h1',{staticClass:"title title--2 primary"},[_vm._v(" "+_vm._s(_vm.content.PasswordReset.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.content.PasswordReset.metadata.subtitle))])]],2),_c('text-input',{attrs:{"type":"password","label":"New Password","name":"password","validations":{
        required: true,
        strong_password: true
      }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('text-input',{attrs:{"type":"password","label":"Confirm New Password","name":"passwordConfirm","validations":{
        required: true,
        confirmation: _vm.password
      }},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('button',{staticClass:"button button--primary button--large extra-spacing-top extra-spacing",class:{
        'button--loading': _vm.updatePasswordIsLoading
      },attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$route.query.created ? _vm.content.CreatePassword.metadata.cta : _vm.content.PasswordReset.metadata.cta)+" ")]),_c('help-link')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }